import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {CreateProductionCatalogueRequest} from '../_model/create-production-catalogue-request';
import {UpdateProductionCatalogueRequest} from '../_model/update-production-catalogue-request';
import {ProductionCatalogue} from '../_model/production-catalogue';
import {FormArray, FormGroup} from '@angular/forms';
import {LaunchProductionCatalogueRequest} from '../_model/launch-production-catalogue-request';

@Component({
	selector: 'app-production-catalogue-form-page',
	templateUrl: './production-catalogue-form-page.component.html'
})
export class ProductionCatalogueFormPageComponent implements AfterViewChecked {

	@Input() productionCatalogue: ProductionCatalogue;
	@Input() copy = false;
	@Input() launch = false;

	@Output()
	public closePage: EventEmitter<void> = new EventEmitter();

	@Output()
	public createCatalogue: EventEmitter<CreateProductionCatalogueRequest> = new EventEmitter();

	@Output()
	public updateCatalogue: EventEmitter<UpdateProductionCatalogueRequest> = new EventEmitter();

	@Output()
	public launchCatalogue: EventEmitter<LaunchProductionCatalogueRequest> = new EventEmitter();

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	close(): void {
		this.closePage.emit();
	}

	submitForm(form: FormGroup): void {
		if (this.launch) {
			this.launchCatalogue.emit(this.getLaunchProductionCatalogueRequest(form));
		} else {
			this.createCatalogue.emit(this.getCreateProductionCatalogueRequest(form));
		}
	}

	get i18nSubmit(): string {
		if (this.copy) {
			return 'general.copy';
		}
		if (this.launch) {
			return 'general.launch';
		}
		return this.productionCatalogue ? 'general.save' : 'general.create';
	}

	get titleTranslationKey(): string {
		if (this.launch) {
			return 'production-catalogue.launch.title';
		} else {
			return this.productionCatalogue ? 'production-catalogue.edit.title' : 'production-catalogue.form.title';
		}
	}

	getCreateProductionCatalogueRequest(form: FormGroup): CreateProductionCatalogueRequest {
		const articles = form.get('articles').value;

		articles.forEach((article: any) => {
			if (typeof article.productionDate !== 'string') {
				const day = String(article.productionDate['day']).padStart(2, '0');
				const month = String(article.productionDate['month']).padStart(2, '0');
				const year = article.productionDate['year'];
				article.productionDate = year + '-' + month + '-' + day;
			}
		});

		return {
			airacCycle: form.get('airacCycle').value,
			articleProductions: articles,
			identification: form.get('identification').value,
			projectUuid: form.get('project').value,
			requesterUserUuid: form.get('requesterUserUuid').value
		};
	}

	getLaunchProductionCatalogueRequest(form: FormGroup): LaunchProductionCatalogueRequest {
		const launchForm = form.get('launchForm') as FormGroup;
		const articles = launchForm.get('launchArticles') as FormArray;
		const articleDatasets = {} as any;

		articles.controls
			.map(control => control as FormGroup)
			.filter(articleFormGroup => articleFormGroup.get('selected').value)
			.forEach(articleFormGroup => {
				const articleUuid = articleFormGroup.get('articleUuid').value;
				const datasetUuid = articleFormGroup.get('dataset').value.uuid;
				articleDatasets[articleUuid] = datasetUuid;
			});

		return {
			uuid: this.productionCatalogue.uuid,
			launchType: launchForm.get('type').value,
			articleDataSetUuids: articleDatasets
		};
	}

}

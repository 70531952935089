import {NgModule} from '@angular/core';
import {ProductionCatalogueService} from './_service/production-catalogue.service';
import {ProductionCatalogueOverviewComponent} from './production-catalogue-overview/production-catalogue-overview.component';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../shared/table/table.module';
import {PaginationModule} from '../shared/pagination/pagination.module';
import {CommonModule} from '@angular/common';
import {PermissionsModule} from '../shared/permission/permissions.module';
import {SearchModule} from '../shared/search/search.module';
import {ProductionCatalogueFormComponent} from './production-catalogue-form/production-catalogue-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ModularFormsModule} from '../shared/modular-forms/modular-forms.module';
import {CommentFormComponent} from './production-catalogue-form/comment-form/comment-form.component';
import {ArticleFormComponent} from './production-catalogue-form/article-form/article-form.component';
import {ErrorHandlingModule} from '../shared/error-handling/error-handling.module';
import {ModalModule} from '../shared/modal/modal.module';
import {DropdownModule} from '../shared/dropdown-multiselect/dropdown.module';
import {ProductionCatalogueFormPageComponent} from './production-catalogue-form-page/production-catalogue-form-page.component';
import {LaunchArticleFormComponent} from './production-catalogue-form/launch-article-form/launch-article-form.component';
import {LaunchHistoryOverviewComponent} from './launch-history-overview/launch-history-overview.component';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {LaunchArticleModule} from '../launcharticle/launch-article.module';
import {LaunchModalComponent} from './launch-modal/launch-modal.component';
import {ProductionCatalogueEditComponent} from './production-catalogue-details/production-catalogue-edit/production-catalogue-edit.component';
import {ProductionCatalogueViewComponent} from './production-catalogue-details/production-catalogue-view/production-catalogue-view.component';

@NgModule({
	declarations: [
		ProductionCatalogueOverviewComponent,
		ProductionCatalogueFormComponent,
		CommentFormComponent,
		ArticleFormComponent,
		ProductionCatalogueFormPageComponent,
		LaunchArticleFormComponent,
		LaunchHistoryOverviewComponent,
		LaunchModalComponent,
		ProductionCatalogueViewComponent,
		ProductionCatalogueEditComponent
	],
	providers: [
		ProductionCatalogueService
	],
	imports: [
		CommonModule,
		TranslateModule,
		TableModule,
		PaginationModule,
		PermissionsModule,
		SearchModule,
		ReactiveFormsModule,
		ModularFormsModule,
		ErrorHandlingModule,
		ModalModule,
		DropdownModule,
		NgbTooltip,
		LaunchArticleModule
	]
})
export class ProductionCatalogueModule {

}

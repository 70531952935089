import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {map, Observable, of, ReplaySubject, Subscription, switchMap} from 'rxjs';
import {User} from '../_model/user';
import {Page} from '../../shared/pagination/page';
import {environment} from '../../../environments/environment';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {FilterCriteria} from '../../shared/model/filter-criteria';
import {ContentArray} from '../../shared/model/content-array';
import {CreateOrUpdateUserRequest} from '../create.or.update.user.request';

@Injectable({
	providedIn: 'root'
})
export class UserService implements OnDestroy {

	subscription = new Subscription();
	private currentUser$ = new ReplaySubject<User>(1);
	private baseUrl = environment.backend_url + '/user';

	constructor(private http: HttpClient,
				private oidcSecurityService: OidcSecurityService) {
		this.subscription.add(this.oidcSecurityService.isAuthenticated$.pipe(
			switchMap((authenticationResult) => {
				if (authenticationResult.isAuthenticated) {
					return this.http.get<User>(`${this.baseUrl}/current`);
				}
				return of(null);
			})
		).subscribe({
			next: user => this.currentUser$.next(user),
			error: err => this.currentUser$.error(err)
		}));
	}

	isCurrentUserInternal(): Observable<boolean> {
		return this.getCurrentUser().pipe(map(user => user !== null ? user.roles.some(role => role.isInternal === true) : false));
	}

	getPermissionsForCurrentUser(): Observable<string[]> {
		return this.http.get<string[]>(`${this.baseUrl}/current/permissions`);
	}

	getCurrentUser(): Observable<User> {
		return this.currentUser$;
	}

	create(request: CreateOrUpdateUserRequest): Observable<User> {
		return this.http.post<User>(this.baseUrl, request);
	}

	search(pageNumber: number, contentSearchCriteria: ContentArray, searchValue: string): Observable<Page<User>> {
		let params = new HttpParams();
		params = params.append('page', pageNumber);
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get<Page<User>>(this.baseUrl + '/search', {params: params});
	}

	update(user: CreateOrUpdateUserRequest): Observable<User> {
		return this.http.put<User>(`${this.baseUrl}/${user.uuid}`, user);
	}

	delete(userUuid: string): Observable<void> {
		return this.http.delete<void>(`${this.baseUrl}/${userUuid}`);
	}

	export(contentSearchCriteria: ContentArray, searchValue: string): Observable<HttpResponse<Blob>> {
		let params = new HttpParams();
		if (searchValue) {
			params = params.append('search.*', searchValue);
		}
		Object.keys(contentSearchCriteria)
			.forEach(key => contentSearchCriteria[key]
				.forEach(value => params = params.append('filter.' + key, value)));
		return this.http.get(this.baseUrl + '/export', {observe: 'response', responseType: 'blob', params});
	}

	getFilterCriteria(): Observable<FilterCriteria[]> {
		return this.http.get<FilterCriteria[]>(`${this.baseUrl}/filtercriteria`);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ProductionCatalogueService} from '../../_service/production-catalogue.service';
import {Observable, Subscription} from 'rxjs';
import {ToastrMessageType} from '../../../shared/toastr/toastr.message.type';
import {ToastrMessageService} from '../../../shared/toastr/toastr.message.service';
import {TranslateService} from '@ngx-translate/core';
import {ProductionCatalogue} from '../../_model/production-catalogue';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'app-production-catalogue-view',
	templateUrl: 'production-catalogue-view.component.html'
})
export class ProductionCatalogueViewComponent implements OnInit, OnDestroy {
	private subscription = new Subscription();

	productionCatalogue$: Observable<ProductionCatalogue>;
	productionCatalogueUuid: string | null = null;

	constructor(private readonly productionCatalogueService: ProductionCatalogueService,
				private readonly toastrMessageService: ToastrMessageService,
				private readonly translateService: TranslateService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute,
				private readonly location: Location) {
	}

	ngOnInit(): void {
		this.activatedRoute.queryParams.subscribe((params): void => {
			this.productionCatalogueUuid = params['uuid'];
			this.productionCatalogue$ = this.productionCatalogueService.get(this.productionCatalogueUuid);
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	closePage(): void {
		this.location.back();
	}

	deleteCatalogue(): void {
		this.subscription.add(this.productionCatalogueService.delete(this.productionCatalogueUuid)
			.subscribe({
				next: () => {
					this.toastrMessageService.show({
						type: ToastrMessageType.SUCCESS,
						messageHTML: `<i class="ti ti-trash me-2 fs-5"></i> ${this.translateService.instant('production-catalogue.delete.success-message')}`
					});
					this.closePage();
				},
				error: (errorResponse) => {
					this.toastrMessageService.show({
						type: ToastrMessageType.ERROR,
						messageHTML: errorResponse.error.errorCode
					});
				}
			}));
	}

	editCatalogue(): void {
		this.router.navigate(['/production-catalogues/edit'], {queryParams: {'uuid': this.productionCatalogueUuid}});
	}

	openLaunchPage(uuid: string): void {
		this.router.navigate(['/production-catalogues/launch', uuid], {
			state: { mode: 'launch' }
		});
	}

	getConfirmationMessage(): string {
		return `<p>${this.translateService.instant('production-catalogue.delete.confirmation-message.are-you-sure')}</p>`;
	}

	exportProductionCatalogueArticles(productionCatalogue: ProductionCatalogue): void {
		this.productionCatalogueService.downloadExportArticles(productionCatalogue.uuid, productionCatalogue.identification);
	}

	exportProductionCatalogue(productionCatalogue: ProductionCatalogue): void {
		this.productionCatalogueService.downloadExport(productionCatalogue.uuid, productionCatalogue.identification);
	}

}

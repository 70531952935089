import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CustomerOverviewComponent} from './customer/customer-overview/customer-overview.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';
import {HomeComponent} from './auth/home.component';
import {AutoLoginComponent} from './auth/auto.login.component';
import {RoleBasedLandingComponent} from './auth/role.based.landing.component';
import {UserOverviewComponent} from './user/user-overview/user-overview.component';
import {DbOverviewComponent} from './db/overview/db-overview.component';
import {canActivate} from './shared/permission/permission.service';
import {NoAccessComponent} from './auth/no-access.component';
import {DbViewerComponent} from './db/db-viewer/db-viewer.component';
import {DbUpdatesViewerComponent} from './db/db-updates-viewer/db-updates-viewer.component';
import {ProgramOverviewComponent} from './notices/program/program-overview/program-overview.component';
import {FolderOverviewComponent} from './notices/folder/folder-overview/folder-overview.component';
import {ContactComponent} from './contact/contact.component';
import {ArticleOverviewComponent} from './article/overview/article-overview.component';
import {NoticeOverviewComponent} from './notices/notice/notice-overview/notice-overview.component';
import {DocumentOverviewComponent} from './notices/notice/document-overview/document-overview.component';
import {UploadViewerComponent} from './db/upload/upload-viewer/upload-viewer.component';
import {UploadDbComponent} from './db/upload/upload-db/upload-db.component';
import {UploadNoticeComponent} from './notices/upload/upload-notice/upload-notice.component';
import {FmsUploadComponent} from './flight-management-system/upload/fms-upload.component';
import {ProjectOverviewComponent} from './project/project-overview/project-overview.component';
import {JobOverviewComponent} from './job/job-overview/job-overview.component';
import {ProductionCatalogueOverviewComponent} from './productioncatalogue/production-catalogue-overview/production-catalogue-overview.component';
import {DatasetOverviewComponent} from './dataset/dataset-overview/dataset-overview.component';
import {DatasetUploadComponent} from './dataset/upload/dataset-upload.component';
import {SourceRadicalIdentifierOverviewComponent} from './sourceradicalidentifier/source-radical-identifier-overview/source-radical-identifier-overview.component';
import {ProductionCatalogueEditComponent} from './productioncatalogue/production-catalogue-details/production-catalogue-edit/production-catalogue-edit.component';
import {ProductionCatalogueViewComponent} from './productioncatalogue/production-catalogue-details/production-catalogue-view/production-catalogue-view.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: HomeComponent,
		data: {
			showSupportingComponents: false
		}
	},
	{
		path: 'customers',
		component: CustomerOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_CUSTOMERS']
		}
	},
	{
		path: 'users',
		component: UserOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_USERS']
		}
	},
	{
		path: 'projects',
		component: ProjectOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PROJECTS']
		}
	},
	{
		path: 'dbs',
		component: DbOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_DBS']
		}
	},
	{
		path: 'dbupload',
		component: UploadDbComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['UPLOAD_DBS']
		}
	},
	{
		path: 'notice-upload',
		component: UploadNoticeComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['UPLOAD_NOTICES']
		}
	},
	{
		path: 'notice-overview',
		component: NoticeOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_NOTICES']
		}
	},
	{
		path: 'doc-overview',
		component: DocumentOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_NOTICES']
		}
	},
	{
		path: 'viewer',
		component: DbViewerComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_VIEWER_DBS']
		}
	},
	{
		path: 'updates-viewer',
		component: DbUpdatesViewerComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_UPDATES_VIEWER_DBS']
		}
	},
	{
		path: 'viewerdbupload',
		component: UploadViewerComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['UPLOAD_VIEWER_DBS']
		}
	},
	{
		path: 'program',
		component: ProgramOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PROGRAMS']
		}
	},
	{
		path: 'folder',
		component: FolderOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_FOLDERS']
		}
	},
	{
		path: 'jobs',
		component: JobOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_JOBS']
		}
	},
	{
		path: 'articles',
		component: ArticleOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_ARTICLES']
		}
	},
	{
		path: 'articles/create',
		component: ArticleOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_ARTICLES']
		}
	},
	{
		path: 'articles/detail/:uuid',
		component: ArticleOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_ARTICLES']
		}
	},
	{
		path: 'fmsupload',
		component: FmsUploadComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_ARTICLES']
		}
	},
	{
		path: 'production-catalogue',
		component: ProductionCatalogueOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'production-catalogues/view',
		component: ProductionCatalogueViewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'production-catalogues/edit',
		component: ProductionCatalogueEditComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'production-catalogues/create',
		component: ProductionCatalogueOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'production-catalogues/copy/:uuid',
		component: ProductionCatalogueOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'production-catalogues/launch/:uuid',
		component: ProductionCatalogueOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_PRODUCTION_CATALOGUES']
		}
	},
	{
		path: 'source-radical-identifier',
		component: SourceRadicalIdentifierOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_SOURCE_RADICAL_IDENTIFIERS']
		}
	},
	{
		path: 'datasets',
		component: DatasetOverviewComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['VIEW_DATASETS']
		}
	},
	{
		path: 'dataset-upload',
		component: DatasetUploadComponent,
		canActivate: [AutoLoginPartialRoutesGuard, canActivate],
		data: {
			permissionsAllowed: ['UPLOAD_DATASETS']
		}
	},
	{
		path: 'autologin',
		component: AutoLoginComponent,
		data: {
			showSupportingComponents: false
		}
	},
	{
		path: 'callback',
		component: RoleBasedLandingComponent,
		data: {
			showSupportingComponents: false
		}
	},
	{
		path: 'no-access',
		component: NoAccessComponent,
		data: {
			showSupportingComponents: false
		}
	},
	{
		path: 'contact',
		component: ContactComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

<div class="d-flex flex-column p-5">
	<div *ngIf="productionCatalogue$ | async as productionCatalogue">
		<div class="d-flex bd-highlight mb-3">
			<div><h1>{{ 'production-catalogue.edit.title' | translate }}</h1></div>
			<div class="ms-auto mt-4">
				<div class="d-flex justify-content-end gap-3">
					<button id="close" type="button" class="btn btn-secondary" (click)="closePage()">{{ "general.go-back" | translate }}
					</button>
					<button id="save-catalogue" type="button" class="btn btn-primary"
							(click)="submitForm(form.form, form.oldStatus?.value)"
							[disabled]="form.isInvalid()">
						<span>{{ 'general.save' | translate }}</span>
					</button>
				</div>
			</div>
		</div>

		<app-production-catalogue-form #form="productioncatalogueForm"
									   [productionCatalogue]="productionCatalogue"
									   [copy]="false"
									   [launch]="false">
		</app-production-catalogue-form>
	</div>
</div>
